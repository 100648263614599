import { FC, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'
// https://dev.azure.com/prem1um/pme/_artifacts/feed/pmi.web/Npm/@pmi.web%2Freact-virtual-warehouse-help/overview/0.0.2-preview.9
import { VirtualWarehouseHelpCarousel } from '@pmi.web/react-virtual-warehouse-help'

import type { ICartItem } from 'typings/cartTypes'
import { fitlineVoucherArticleNumber, useCheckoutContext } from 'context/checkout'
import { useLocalizationContext } from 'context/localization'

import { containerVariants, contentVariants } from './DestinationPage.motion'
import PersistentPortal from 'components/common/PersistentPortal'
import Button from 'components/common/buttons/Button'
import Collapse from 'components/animations/Collapse'
import DestinationOptionCard from 'components/delivery/DestinationOptionCard'
import MessageBox from 'components/common/MessageBox'
import Modal from 'components/common/Modal'

const DestinationPage: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { appLocale } = useLocalizationContext()
  const { t: translated } = useTranslation()
  const { cartItems, selectedDestination, setSelectedDestination, isPaid, isCanceled } =
    useCheckoutContext()
  const [showVWInfoModal, setShowVWInfoModal] = useState(false)
  const cartItemsIneligibleForVirtualWarehouse: ICartItem[] = (cartItems ?? [])
    .filter(i => i.articleNumber !== fitlineVoucherArticleNumber)
    .filter(cartItem => !cartItem?.supportsVirtualWarehouse)

  const closeVWInfoModal = () => {
    setShowVWInfoModal(false)
  }

  return (
    <motion.div
      className={
        'w-full max-w-3xl mx-auto sm:p-4' +
        ' flex-1 overflow-hidden' +
        ' flex flex-col' +
        ' bg-white' +
        ' border border-secondary rounded-t-md' +
        ' shadow-md'
      }
      variants={containerVariants}
    >
      {/* --------------- main content: start --------------- */}
      <motion.div
        className={'flex-1 container p-4 md:p-8' + ' space-y-6 md:space-y-0'}
        variants={contentVariants}
      >
        {/* --------------- delivery destination: start --------------- */}
        <div
          className={
            'w-full mt-2 md:mt-4' +
            ' flex flex-col justify-center items-center' +
            (selectedDestination ? '' : ' gap-y-2 md:gap-y-4')
          }
        >
          <Collapse isExpanded={!selectedDestination}>
            <p className='text-sm text-rose-500'>
              {translated('Choose a destination for this order')}
            </p>
          </Collapse>

          <form
            id='delivery-method'
            className={'w-full flex flex-col justify-center items-center gap-2 md:gap-4'}
            onSubmit={e => e.preventDefault()}
          >
            <DestinationOptionCard
              id={'immediate-purchase'}
              title={translated('Buy now')}
              details={translated('Pay now and get the products')}
              isSelected={selectedDestination === 'immediate-purchase'}
              handleSelection={() => setSelectedDestination('immediate-purchase')}
            />

            <DestinationOptionCard
              id={'virtual-warehouse'}
              title={
                <div className='mb-2 flex items-center justify-start gap-1'>
                  <p className='text-base font-normal'>{translated('Virtual Warehouse')}</p>
                  <span className='px-1 py-0 uppercase text-xs text-white bg-primary'>
                    {translated('New')}
                  </span>
                </div>
              }
              details={
                <div className='text-sm text-justify font-light tracking-wide text-gray-500'>
                  {/* <p className='text-sm'>
                    {translated('Enjoy this risk-free income opportunity.')}
                  </p> */}

                  {/* <ul className=''>
                    {[
                      translated('Buy with no upfront payment'),
                      translated('No shipping hassle'),
                      translated('Commit to selling within 7 days')
                    ].map((text, index) => (
                      <li
                        key={index}
                        className='before:content-["✓"] before:mr-1 before:text-gray-500'
                      >
                        {text}
                      </li>
                    ))}
                  </ul> */}

                  <p className='text-sm'>
                    {translated(
                      'Add products to my inventory to sell them to customers and commit to pay later for unsold items'
                    )}
                  </p>

                  <button
                    className='text-base text-primary underline hover:font-bold'
                    onClick={() => setShowVWInfoModal(true)}
                  >
                    {translated('Learn more')}
                  </button>
                </div>
              }
              isSelected={selectedDestination === 'virtual-warehouse'}
              handleSelection={() => setSelectedDestination('virtual-warehouse')}
              disabled={!!cartItemsIneligibleForVirtualWarehouse?.length}
              customFooter={
                cartItemsIneligibleForVirtualWarehouse?.length ? (
                  <MessageBox
                    type='warning'
                    text={
                      <div>
                        <p className='mb-2 text-sm'>
                          {translated(
                            'You have products in your shopping cart that are not eligible for the Virtual Warehouse:'
                          )}
                        </p>

                        <ul className='list-disc pl-5 text-sm'>
                          {cartItemsIneligibleForVirtualWarehouse.map(product => (
                            <li key={product.articleNumber}>{product.name}</li>
                          ))}
                        </ul>
                      </div>
                    }
                    className='mt-4'
                    showIcon={true}
                  />
                ) : undefined
              }
            />
          </form>
        </div>
        {/* --------------- delivery destination: end --------------- */}
      </motion.div>
      {/* --------------- main content: end --------------- */}

      {/* --------------- footer: start --------------- */}
      <PersistentPortal containerElementSelector='#footer-buttons'>
        {/* --------------- buttons: start --------------- */}
        <div className='flex flex-row justify-between items-center gap-4'>
          <button
            className='flex items-center text-primary'
            onClick={() =>
              navigate(
                { pathname: '..', search: location.search },
                { state: { source: 'checkout-destination' } }
              )
            }
          >
            <RiArrowLeftSLine size={24} className='inline-block' />
            <span>{translated('Back')}</span>
          </button>

          <Button
            onClick={() => {
              navigate(
                { pathname: '../delivery', search: location.search },
                { state: { source: 'checkout-destination' } }
              )
            }}
            disabled={!selectedDestination || isPaid || isCanceled}
            contentClassName='flex items-center'
            variant='primary'
          >
            <span>{translated('Confirm')}</span>
            <RiArrowRightSLine size={24} className='-mr-2' />
          </Button>
        </div>
        {/* --------------- buttons: end --------------- */}
      </PersistentPortal>
      {/* --------------- footer: end --------------- */}

      {/* --------------- virtual warehouse info modal: start --------------- */}

      <Modal isOpen={showVWInfoModal} onClose={closeVWInfoModal} size='md'>
        <VirtualWarehouseHelpCarousel
          // @ts-ignore
          language={appLocale ?? 'en-US'}
          onComplete={closeVWInfoModal}
        />
      </Modal>
      {/* --------------- virtual warehouse info modal: end --------------- */}
    </motion.div>
  )
}

export default DestinationPage
