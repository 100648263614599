import { FC } from 'react'

import { IDefaultIconProps } from './iconTypes'

const FitLineVoucherIcon: FC<IDefaultIconProps> = ({ className }) => (
  <svg width='32' height='31' viewBox='0 0 32 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M29.0934 14.9979V11.4723C29.0934 9.74016 27.7152 8.32467 25.9979 8.26288V6.09885C25.9979 4.97166 25.0808 4.05462 23.9536 4.05462H18.3257L17.5989 2.03451C17.4136 1.51936 17.0385 1.10771 16.5428 0.875427C16.047 0.643085 15.4906 0.61832 14.9762 0.805572L4.76354 4.52263C4.24968 4.70965 3.83972 5.08573 3.60913 5.5816C3.37854 6.07748 3.35512 6.63333 3.54325 7.14685L3.95356 8.26679C2.27524 8.37058 0.941406 9.76825 0.941406 11.4723V27.3589C0.941406 29.1302 2.38247 30.5713 4.15377 30.5713H25.8811C27.6524 30.5713 29.0934 29.1302 29.0934 27.3589V23.7165C30.0827 23.5743 30.8456 22.7215 30.8456 21.6934V17.0209C30.8456 15.9929 30.0827 15.14 29.0934 14.9979ZM29.0934 21.6934C29.0934 21.8545 28.9624 21.9855 28.8014 21.9855H24.1289C22.6796 21.9855 21.5006 20.8064 21.5006 19.3572C21.5006 17.9079 22.6796 16.7289 24.1289 16.7289H28.8014C28.9624 16.7289 29.0934 16.8599 29.0934 17.0209V21.6934ZM5.18851 6.54409C5.17522 6.50804 5.16921 6.46971 5.17083 6.43133C5.17244 6.39294 5.18164 6.35526 5.19791 6.32045C5.21405 6.28559 5.23694 6.25428 5.26525 6.22831C5.29356 6.20235 5.32673 6.18225 5.36285 6.16918L15.5755 2.45212C15.6747 2.41608 15.7582 2.44289 15.7993 2.4621C15.8404 2.48132 15.9145 2.52839 15.9502 2.62769L16.4636 4.05468H16.0687C14.9415 4.05468 14.0245 4.97172 14.0245 6.09891V8.25996H5.81714L5.18851 6.54409ZM24.2457 6.09885V8.2599H15.7767V6.09885C15.7767 5.93783 15.9077 5.80682 16.0687 5.80682H23.9536C24.1147 5.80682 24.2457 5.93783 24.2457 6.09885ZM25.8811 28.8191H4.15377C3.34864 28.8191 2.69361 28.164 2.69361 27.3589V11.4723C2.69361 10.6671 3.34864 10.0121 4.15377 10.0121H25.8811C26.6862 10.0121 27.3412 10.6671 27.3412 11.4723V14.9767H24.1289C21.7135 14.9767 19.7484 16.9418 19.7484 19.3572C19.7484 21.7726 21.7135 23.7377 24.1289 23.7377H27.3412V27.3589C27.3412 28.164 26.6862 28.8191 25.8811 28.8191Z'
      fill='currentColor'
      className={className}
    ></path>
    <path
      d='M24.1288 20.2336C24.6126 20.2336 25.0049 19.8414 25.0049 19.3575C25.0049 18.8737 24.6126 18.4814 24.1288 18.4814C23.6449 18.4814 23.2527 18.8737 23.2527 19.3575C23.2527 19.8414 23.6449 20.2336 24.1288 20.2336Z'
      fill='currentColor'
    ></path>
  </svg>
)

export default FitLineVoucherIcon
