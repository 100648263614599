export const supportedLanguageCodes = [
  'bg-BG',
  'cs-CZ',
  'da-DK',
  'de-DE',
  'en-US',
  'es-ES',
  'fi-FI',
  'fr-FR',
  'hu-HU',
  'id-ID',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'nl-NL',
  'nn-NO',
  'pl-PL',
  'ru-RU',
  'sv-SE',
  'tr-TR',
  'uk-UA',
  'zh-CN',
  'is-IS',
  'vi-VN',
  'et-EE',
  'lt-LT'
]
export type LocaleCodeType = (typeof supportedLanguageCodes)[number]

export type LanguageNameType =
  | 'български'
  | 'Česky'
  | 'Dansk'
  | 'Deutsch'
  | 'English'
  | 'Español'
  | 'Suomi'
  | 'Français'
  | 'Magyar'
  | 'Indonesia'
  | 'Italiano'
  | '日本語'
  | '한국어'
  | 'Nederlands'
  | 'Nørsk'
  | 'Polski'
  | 'Русский'
  | 'Svenska'
  | 'Türkçe'
  | 'Українська'
  | '中文'

export interface ILocale {
  readonly name: LanguageNameType
  readonly code: LocaleCodeType
}
