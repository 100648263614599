import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import type { CustomErrorMessage, IApiError, IGenericServerError } from 'typings/errorTypes'
import type {
  IBasicBasketItem,
  IBasketWithItemDetails,
  IGetPromoCodeInfoResponse
} from 'typings/checkoutApi'
import type { ICartItem } from 'typings/cartTypes'

import config from 'config'
import { bufferReducer, mergeRemoteBasketWithBuffer } from 'utils/cartUtils'
import { fetchBasketContent, fetchPromoCodeInfo, setBasketContent } from 'services/checkout'
import { useAuthContext } from 'context/auth'
import { useAppContext } from 'context/app'
import { useShopContext } from 'context/shop/ShopContext'
import { useLocalizationContext } from 'context/localization'
import { showToast } from 'components/layout/ToastNotification'
import { fitlineVoucherArticleNumber } from 'context/checkout/CheckoutContext'

interface ICartContext {
  readonly isCartContentLoading: boolean
  readonly isCartUploading: boolean
  readonly isCartContentError: boolean
  readonly cartContentError: string
  readonly cartItems: ICartItem[]
  readonly addUnitsToCart: (articleNumber: string, quantity: number) => void
  readonly removeUnitsFromCart: (articleNumber: string, quantity: number) => void
  readonly setCartArticleUnits: (articleNumber: string, quantity: number) => void
  readonly removeArticleFromCart: (articleNumber: string) => void
  readonly emptyCart: () => void
  readonly itemValidationErrorMap: Map<string, CustomErrorMessage> | undefined
  readonly setItemValidationErrorMap: Dispatch<
    SetStateAction<Map<string, CustomErrorMessage> | undefined>
  >
  readonly cartContentQueryKey: string[]
  readonly shopCartContentQueryKey: string[]
  readonly cartPromoCode?: string
  readonly validatedPromoCode: string | null
  readonly sponsorNamePromoCode: string
  readonly cartSubtotal?: number
  readonly totalGrossExcludingNegativeItems: number
  readonly userHasFitlineVouchers: boolean
}

interface IECPProps {
  readonly children?: ReactNode
}

const cartBufferSynchronizationDebounceSpan = config.cartBufferSynchronizationDebounceSpan
const forcedCartSyncAnimationDuration = 1000

const CartContext = createContext<ICartContext | null>(null)

export const CartProvider: FC<IECPProps> = ({ children }) => {
  const { localeCode: localeCodePathParam } = useParams()
  const { t: translated } = useTranslation()
  const queryClient = useQueryClient()
  const { accessToken } = useAuthContext()
  const {
    cartId,
    setCartId,
    configuredLanguages,
    setConfiguredLanguages,
    setShopId,
    shopId,
    userPromoCode
  } = useAppContext()
  const { appLocale } = useLocalizationContext()
  const { productMap } = useShopContext()

  const cartBufferDebounceRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const forcedCartSyncAnimationRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  /**
   * The cartBuffer temporarily stores the items added by the user.
   * Its content is submitted to the checkout API after a debounce period.
   */
  const [cartBuffer, bufferDispatch] = useReducer(bufferReducer, new Map())

  // force the cart synchronization process to display animation for at least forcedCartSyncAnimationDuration milliseconds
  const [isForcedCartSyncAnimationSpan, setIsForcedCartSyncAnimationSpan] = useState(false)
  const [itemValidationErrorMap, setItemValidationErrorMap] = useState<
    Map<string, CustomErrorMessage> | undefined
  >()

  const cartContentQueryKey = useMemo(
    () => ['cart', cartId ?? '', appLocale ?? ''],
    [appLocale, cartId]
  )
  const shopCartContentQueryKey = useMemo(
    () => ['cart', cartId ?? '', appLocale ?? '', 'shop'],
    [appLocale, cartId]
  )

  const {
    data: cartData,
    error: cartQueryError,
    isLoading: isCartQueryLoading,
    isError: isCartQueryError
  } = useQuery<IBasketWithItemDetails, IApiError>({
    enabled: !!cartId && !!accessToken && !!appLocale,
    queryKey: shopCartContentQueryKey,
    queryFn: () =>
    fetchBasketContent(
      cartId ?? '',
      accessToken ?? '',
      appLocale,
      ['items']
    ) as Promise<IBasketWithItemDetails>, //prettier-ignore
    staleTime: Infinity,
    onSuccess: cartDataResponse => {
      // carts already authorized for payment must not be used for further operations
      if (cartDataResponse.status === 'paymentAuthorized') {
        setCartId(undefined)
      } else {
        if (!shopId) setShopId(cartDataResponse.shopId)
      }
    },
    onError: cartContentError => {
      showToast({
        type: 'error',
        title: translated('Failed to fetch cart data')
      })
      console.error(cartContentError)
    }
  })

  const cartLanguages = cartData?.languages
  const cartItems = cartData?.items
  const cartPromoCode = cartData?.affiliateDiscount?.[0]?.promoCode

  const { data: promoCodeInfo } = useQuery<
    IGetPromoCodeInfoResponse,
    IApiError | IGenericServerError
  >({
    enabled: !cartPromoCode && !!userPromoCode,
    queryKey: ['promocode', userPromoCode],
    queryFn: () => fetchPromoCodeInfo(userPromoCode ?? ''),
    staleTime: Infinity
  })

  const validatedPromoCode =
    !!userPromoCode && promoCodeInfo?.status === 'active' ? userPromoCode : null

  const sponsorNamePromoCode = promoCodeInfo?.userName ?? ''

  useEffect(() => {
    if (!cartLanguages?.length) return

    if (
      cartLanguages?.length > 0 &&
      configuredLanguages?.length <= 1 &&
      configuredLanguages?.length != cartLanguages?.length
    )
      setConfiguredLanguages(cartLanguages)
  }, [setConfiguredLanguages, cartLanguages, configuredLanguages?.length])

  const setCartContentMutation = useMutation<IBasketWithItemDetails, IApiError, IBasicBasketItem[]>(
    {
      mutationFn: newCartItems => {
        setIsForcedCartSyncAnimationSpan(true)
        return setBasketContent(
          cartId ?? '',
          accessToken ?? '',
          newCartItems,
          localeCodePathParam ?? ''
        )
      },
      onError: error => {
        Object.entries(error?.errors ?? []).forEach(([errorKey, errorValueArray]) =>
          showToast({
            type: 'error',
            title: `Error: ${errorKey}`,
            message: errorValueArray.join(',\n')
          })
        )
      },
      onSettled: () => {
        // synchronize the cart state with the values saved on the back-end
        queryClient.invalidateQueries(cartContentQueryKey)
        emptyBuffer()
      }
    }
  )

  const mergedCartItems = useMemo(
    () =>
      mergeRemoteBasketWithBuffer(cartItems ?? [], cartBuffer).map(
        ({ articleNumber, quantity }) => {
          const product =
            cartItems?.find(item => item.articleNumber === articleNumber) ??
            productMap.get(articleNumber)
          const restrictionValueList = Object.values(product?.restrictions ?? {})
          const maxAmount =
            restrictionValueList?.length > 0 ? Math.min(...restrictionValueList) : Infinity

          return {
            articleNumber,
            quantity,
            name: product?.details?.attributes?.name?.value ?? '',
            image: product?.details?.media[0]?.url ?? '',
            price: product?.details?.displayPrice ?? 0,
            currency: product?.details?.currencyCode ?? '',
            points: product?.details?.points ?? 0,
            inStock: product?.inStock ?? true,
            maxAmount,
            amountInUnits: product?.details?.netWeight,
            unit: product?.details?.netWeightUnit,
            supportsVirtualWarehouse: product?.details?.supportsVirtualWarehouse ?? false,
            addOnItems: !!product && 'addOnItems' in product ? product.addOnItems : []
          }
        }
      ),
    [cartBuffer, cartItems, productMap]
  )

  // Push the local cart buffer content to the back-end cart after a debounce period
  useEffect(() => {
    if (
      cartBuffer.size &&
      cartId &&
      !setCartContentMutation.isLoading &&
      !setCartContentMutation.isError
    ) {
      if (cartBufferDebounceRef.current) clearTimeout(cartBufferDebounceRef.current)
      cartBufferDebounceRef.current = setTimeout(() => {
        setCartContentMutation.mutate(mergedCartItems)
      }, cartBufferSynchronizationDebounceSpan)

      return () => {
        if (cartBufferDebounceRef.current) clearTimeout(cartBufferDebounceRef.current)
      }
    }
  }, [cartBuffer, cartId, mergedCartItems, setCartContentMutation])

  // disable the forced cart sync animation
  useEffect(() => {
    if (isForcedCartSyncAnimationSpan) {
      if (forcedCartSyncAnimationRef.current) clearTimeout(forcedCartSyncAnimationRef.current)
      forcedCartSyncAnimationRef.current = setTimeout(
        () => setIsForcedCartSyncAnimationSpan(false),
        forcedCartSyncAnimationDuration
      )

      return () => {
        if (forcedCartSyncAnimationRef.current) clearTimeout(forcedCartSyncAnimationRef.current)
      }
    }
  }, [isForcedCartSyncAnimationSpan])

  const addUnitsToCart = useCallback(
    (articleNumber: string, quantity: number) => {
      if (!cartId || setCartContentMutation.isLoading || setCartContentMutation.isError) return
      bufferDispatch({ type: 'addUnits', payload: { articleNumber, quantity } })
    },
    [cartId, setCartContentMutation.isError, setCartContentMutation.isLoading]
  )

  const removeUnitsFromCart = useCallback(
    (articleNumber: string, quantity: number) => {
      if (!cartId || setCartContentMutation.isLoading || setCartContentMutation.isError) return
      bufferDispatch({ type: 'removeUnits', payload: { articleNumber, quantity } })
    },
    [cartId, setCartContentMutation.isError, setCartContentMutation.isLoading]
  )

  const setCartArticleUnits = useCallback(
    (articleNumber: string, newQuantity: number) => {
      if (!cartId || setCartContentMutation.isLoading || setCartContentMutation.isError) return
      setCartContentMutation.mutate(
        mergedCartItems.map(cartItem =>
          cartItem.articleNumber === articleNumber
            ? { ...cartItem, quantity: newQuantity }
            : cartItem
        )
      )
    },
    [cartId, mergedCartItems, setCartContentMutation]
  )

  const removeArticleFromCart = useCallback(
    (articleNumber: string) => {
      if (!cartId || setCartContentMutation.isLoading || setCartContentMutation.isError) return
      setCartContentMutation.mutate(
        mergedCartItems.filter(cartItem => cartItem.articleNumber !== articleNumber)
      )
    },
    [cartId, mergedCartItems, setCartContentMutation]
  )

  const emptyBuffer = useCallback(() => {
    bufferDispatch({ type: 'emptyBuffer' })
  }, [])

  const emptyCart = useCallback(() => {
    if (!cartId || setCartContentMutation.isLoading || setCartContentMutation.isError) return
    setCartContentMutation.mutate([])
  }, [cartId, setCartContentMutation])

  const isCartContentError = isCartQueryError
  const cartContentError = !isCartContentError
    ? ''
    : cartQueryError?.title
    ? `${cartQueryError?.status} ${cartQueryError?.title}`
    : translated('Failed to fetch cart data')
  const isCartContentLoading = isCartQueryLoading
  const isCartUploading = setCartContentMutation.isLoading || isForcedCartSyncAnimationSpan

  const cartProviderValue = useMemo(
    () => ({
      isCartContentLoading,
      isCartUploading,
      isCartContentError,
      cartContentError,
      cartItems: mergedCartItems,
      addUnitsToCart,
      removeUnitsFromCart,
      setCartArticleUnits,
      removeArticleFromCart,
      emptyCart,
      itemValidationErrorMap,
      setItemValidationErrorMap,
      cartContentQueryKey,
      shopCartContentQueryKey,
      cartPromoCode,
      validatedPromoCode,
      sponsorNamePromoCode,
      cartSubtotal: cartData?.totals?.itemsDisplayTotal,
      totalGrossExcludingNegativeItems: cartData?.totals.totalGrossExcludingNegativeItems ?? 0,
      userHasFitlineVouchers:
        mergedCartItems.filter(i => i.articleNumber === fitlineVoucherArticleNumber).length > 0
    }),
    [
      isCartContentLoading,
      isCartUploading,
      isCartContentError,
      cartContentError,
      mergedCartItems,
      addUnitsToCart,
      removeUnitsFromCart,
      setCartArticleUnits,
      removeArticleFromCart,
      emptyCart,
      itemValidationErrorMap,
      cartContentQueryKey,
      shopCartContentQueryKey,
      cartPromoCode,
      validatedPromoCode,
      sponsorNamePromoCode,
      cartData?.totals?.itemsDisplayTotal,
      cartData?.totals.totalGrossExcludingNegativeItems
    ]
  )

  return <CartContext.Provider value={cartProviderValue}>{children}</CartContext.Provider>
}

export const useCartContext = () => {
  const context = useContext(CartContext)

  if (context === null) {
    throw new Error('useCartContext must be used within CartProvider')
  }

  return context
}
