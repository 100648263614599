import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { FaShoppingCart } from 'react-icons/fa'
import { useCartContext } from 'context/cart'
import AnimatedNumber from 'components/effects/AnimatedNumber'
import { fitlineVoucherArticleNumber } from 'context/checkout/CheckoutContext'

type CartButtonProps = {
  setCartDrawerState: Dispatch<SetStateAction<boolean>>
}

const CartButton: FC<CartButtonProps> = ({ setCartDrawerState }) => {
  const { cartItems } = useCartContext()

  const cartItemsExcludingFitlineVoucher = cartItems.filter(
    i => i.articleNumber !== fitlineVoucherArticleNumber
  )

  const numItemsInCart = cartItemsExcludingFitlineVoucher.reduce(
    (output, item) => output + item.quantity,
    0
  )

  const toggleCartDrawerState = useCallback(() => {
    setCartDrawerState(prevState => !prevState)
  }, [setCartDrawerState])

  return numItemsInCart > 0 ? (
    <button
      onClick={toggleCartDrawerState}
      className={
        'z-20 relative h-10 w-10 flex justify-center items-center' +
        ' rounded-full border-solid ' +
        ' hover:bg-primary hover:text-white'
      }
    >
      <FaShoppingCart size={24} className='-ml-0.5' />

      {numItemsInCart ? (
        <div
          className={
            'absolute -top-2 -right-2 px-1.5 py-1' +
            ' inline-flex justify-center items-center' +
            ' rounded-full' +
            ' bg-secondary text-primary text-xs font-bold leading-none'
          }
        >
          <AnimatedNumber value={numItemsInCart} />
        </div>
      ) : null}
    </button>
  ) : null
}

export default CartButton
