import { FC, useEffect, useMemo, useState } from 'react'
import { Navigate, Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { supportedLanguageCodes, type ILocale, type LocaleCodeType } from 'typings/languageTypes'
import type { IShopBasicsResponse } from 'typings/shopApi'
import type { IApiError, IGenericServerError } from 'typings/errorTypes'

import config from 'config/index'
import { codeUnknownLocale } from 'localization/.'
import { fetchShopBasics } from 'services/shop'
import useSessionStorage from 'hooks/useSessionStorage'
import AppContextProvider from 'context/app/AppContext'
import { LocalizationProvider } from 'context/localization'

import PageLayout from 'components/layout/PageLayout'

const App: FC = () => {
  const { pathname, search, hash } = useLocation()
  const {
    localeCode: localeCodePathParam,
    shopId: shopIdPathParam,
    cartId: cartIdPathParam,
    vcartId: vcartIdPathParam
  } = useParams()
  const [searchParams] = useSearchParams()
  const promoCodeSearchParam = searchParams.get(config.promoCodeQueryParamKey)

  const [shopId, setShopId] = useState<string | undefined>(shopIdPathParam)
  const [cartId, setCartId] = useState<string | undefined>(cartIdPathParam)
  const [isPromoCodeEnabled, setIsPromoCodeEnabled] = useState(false)
  const [isFitlineVoucherEnabled, setIsFitlineVoucherEnabled] = useState(false)
  const [userPromoCode, setUserPromoCode] = useSessionStorage<string | null>(
    config.promoCodeStorageKey,
    promoCodeSearchParam
  )
  const [configuredLanguages, setConfiguredLanguages] = useState<ILocale[]>([])

  const isShopIdPathParamPresent = !!shopIdPathParam?.length
  const isCartIdPathParamPresent = !!cartIdPathParam?.length
  const isVcartIdPathParamPresent = !!vcartIdPathParam?.length
  const isValidLanguageCodeInUrl = supportedLanguageCodes.includes(
    localeCodePathParam as LocaleCodeType
  )

  const queryShopId =
    !isShopIdPathParamPresent && !isCartIdPathParamPresent ? localeCodePathParam : shopIdPathParam

  useEffect(() => {
    if (promoCodeSearchParam && promoCodeSearchParam !== userPromoCode)
      setUserPromoCode(promoCodeSearchParam)
  }, [userPromoCode, promoCodeSearchParam, setUserPromoCode])

  // Used to redirect from /:shopId to /:localeCode/shop/:shopId
  const {
    data: shopBasics,
    isSuccess: isShopBasicsQuerySuccess,
    error: shopLookupError
  } = useQuery<IShopBasicsResponse, IApiError | IGenericServerError>({
    enabled:
      localeCodePathParam !== 'vcart' &&
      localeCodePathParam !== 'int' &&
      ((!isShopIdPathParamPresent && !isCartIdPathParamPresent && !isVcartIdPathParamPresent) ||
        (!isValidLanguageCodeInUrl && isShopIdPathParamPresent)),
    queryKey: ['shop', queryShopId],
    queryFn: () =>
      fetchShopBasics({
        shopId: queryShopId ?? ''
      }) as Promise<IShopBasicsResponse>,
    staleTime: Infinity,
    onSuccess: basicShopConfig => {
      setShopId(basicShopConfig.id)
      setConfiguredLanguages(basicShopConfig.languages)
    }
  })

  const appContextValue = useMemo(
    () => ({
      shopId,
      setShopId,
      cartId,
      setCartId,
      configuredLanguages,
      setConfiguredLanguages,
      userPromoCode,
      isPromoCodeEnabled,
      setIsPromoCodeEnabled,
      isFitlineVoucherEnabled,
      setIsFitlineVoucherEnabled
    }),
    [
      cartId,
      configuredLanguages,
      isPromoCodeEnabled,
      isFitlineVoucherEnabled,
      shopId,
      userPromoCode
    ]
  )

  if (
    isValidLanguageCodeInUrl &&
    !(isCartIdPathParamPresent || isShopIdPathParamPresent || isVcartIdPathParamPresent)
  )
    return <Navigate to='/404' replace={true} />

  if (!isValidLanguageCodeInUrl && ['cart', 'vcart'].includes(localeCodePathParam ?? '')) {
    return <Navigate to={`/${codeUnknownLocale}${pathname}${search}${hash}`} replace={true} />
  }

  // If shop ID not recognized by back-end, redirect from /:shopId to /404
  if (shopLookupError && 'status' in shopLookupError && shopLookupError?.status === 404)
    return <Navigate to='/404' replace={true} />

  // If shop ID recognized, redirect from /:shopId to /:localeCode/shop/:shopId/products
  if (
    !isValidLanguageCodeInUrl &&
    isShopBasicsQuerySuccess &&
    shopBasics.id === localeCodePathParam
  )
    return (
      <Navigate
        to={{
          pathname: `/${codeUnknownLocale}/shop/${localeCodePathParam}/products`,
          search,
          hash
        }}
        replace={true}
      />
    )

  return (
    <AppContextProvider value={appContextValue}>
      <LocalizationProvider>
        <PageLayout>
          <Outlet />
        </PageLayout>
      </LocalizationProvider>
    </AppContextProvider>
  )
}

export default App
