import { createContext, FC, ReactNode, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import type { ICartItem } from 'typings/cartTypes'
import type { IVirtualWarehouseBasket } from 'typings/checkoutApi'
import type { IApiError } from 'typings/errorTypes'

import { codeDefaultLocale } from 'localization/.'
import { fetchVirtualWarehouseBasket } from 'services/checkout'
import { useAppContext } from 'context/app'

import { showToast } from 'components/layout/ToastNotification'
import { LocaleCodeType, supportedLanguageCodes } from 'typings/languageTypes'

interface ICheckoutContext {
  readonly isBasketLoading: boolean
  readonly isBasketQueryError: boolean
  readonly basketQueryError: IApiError | null
  readonly cartCurrency: string
  readonly cartItems: ICartItem[]
  readonly isPaid: boolean
  readonly isCanceled: boolean
  readonly cartSubtotal: number
  readonly cartPoints: number
  readonly idOfBasketTargetCustomer: string
  readonly customer: {
    readonly name: string | null
    readonly isTeamPartner: boolean
  }
  readonly sharerName: string | null
  readonly affiliateDiscount?: number
}

interface IECPProps {
  readonly children: ReactNode
}

const VWCartContext = createContext<ICheckoutContext | null>(null)

export const VWCartProvider: FC<IECPProps> = ({ children }) => {
  const { localeCode: localeCodePathParam, vcartId: vcartIdPathParam } = useParams()
  const { t: translated } = useTranslation()
  const {
    configuredLanguages,
    setConfiguredLanguages,
    setShopId,
    shopId: appShopId
  } = useAppContext()

  const isValidLanguageCodeInUrl = supportedLanguageCodes.includes(
    localeCodePathParam as LocaleCodeType
  )

  const virtualWarehouseCartQueryKey = ['basket', vcartIdPathParam, 'vw', localeCodePathParam]

  const {
    data: basketData,
    error: basketQueryError,
    isLoading: isBasketLoading,
    isError: isBasketQueryError
  } = useQuery<IVirtualWarehouseBasket, IApiError>({
    enabled: !!vcartIdPathParam,
    queryKey: virtualWarehouseCartQueryKey,
    queryFn: () =>
      fetchVirtualWarehouseBasket(
        vcartIdPathParam ?? '',
        isValidLanguageCodeInUrl ? localeCodePathParam : codeDefaultLocale
      ),
    staleTime: Infinity,
    onSuccess: basketDataResponse => {
      if (!appShopId) setShopId(basketDataResponse.shopId)
      const basketLanguages = basketDataResponse?.languages
      if (
        basketLanguages?.length > 0 &&
        configuredLanguages?.length <= 1 &&
        configuredLanguages?.length != basketLanguages?.length
      )
        setConfiguredLanguages(basketLanguages)
    },
    onError: basketContentError => {
      showToast({
        type: 'error',
        title: translated('Failed to fetch cart data')
      })
      console.error(basketContentError)
    }
  })

  const cartItems = useMemo(
    () =>
      (basketData?.items ?? [])
        .filter(
          product =>
            product?.articleNumber &&
            product?.details?.attributes?.name &&
            product?.details?.displayPrice != null &&
            product?.details?.currencyCode
        )
        .map(product => {
          const restrictionValueList = Object.values(product?.restrictions ?? {})
          const maxAmount =
            restrictionValueList?.length > 0 ? Math.min(...restrictionValueList) : Infinity

          return {
            articleNumber: product.articleNumber,
            quantity: product.quantity,
            name: product?.details.attributes?.name?.value ?? '',
            image: product?.details.media[0]?.url ?? '',
            price: product?.details.displayPrice ?? 0,
            currency: product?.details.currencyCode ?? '',
            points: product?.details.points ?? 0,
            inStock: product?.inStock ?? true,
            maxAmount,
            supportsVirtualWarehouse: product?.details?.supportsVirtualWarehouse ?? false,
            addOnItems: !!product && 'addOnItems' in product ? product.addOnItems : []
          }
        }),
    [basketData?.items]
  )

  const sharerName = basketData?.sharerDetails?.firstName
    ? basketData?.sharerDetails?.firstName +
      (basketData?.sharerDetails?.lastName ? ' ' + basketData?.sharerDetails?.lastName : '')
    : null
  const customerName = basketData?.customerDetails?.firstName
    ? basketData?.customerDetails?.firstName +
      (basketData?.customerDetails?.lastName ? ' ' + basketData?.customerDetails?.lastName : '')
    : null
  const affiliateDiscount = basketData?.affiliateDiscount?.[0]?.value

  const checkoutProviderValue = useMemo(
    () => ({
      isBasketLoading,
      isBasketQueryError,
      basketQueryError,
      cartCurrency: basketData?.items[0]?.details?.currencyCode ?? 'EUR',
      cartItems,
      cartSubtotal: basketData?.totals?.itemsDisplayTotal ?? 0,
      cartPoints: basketData?.totals?.points ?? 0,
      isPaid: basketData?.status === 'paymentAuthorized',
      isCanceled: basketData?.status === 'canceled',
      idOfBasketTargetCustomer: basketData?.customerId ?? '',
      sharerName,
      customer: {
        name: customerName,
        isTeamPartner: basketData?.customerDetails?.isTeamPartner ?? false
      },
      affiliateDiscount
    }),
    [
      isBasketLoading,
      isBasketQueryError,
      basketQueryError,
      basketData?.items,
      basketData?.totals?.itemsDisplayTotal,
      basketData?.totals?.points,
      basketData?.status,
      basketData?.customerId,
      basketData?.customerDetails,
      cartItems,
      sharerName,
      customerName,
      affiliateDiscount
    ]
  )

  return <VWCartContext.Provider value={checkoutProviderValue}>{children}</VWCartContext.Provider>
}

export const useVWCartContext = () => {
  const context = useContext(VWCartContext)

  if (context === null) {
    throw new Error('useVWCartContext must be used within VWCartProvider')
  }

  return context
}
